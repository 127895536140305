<template>
  <nav class="bg-gray-800 p-4">
    <div class="container mx-auto flex justify-between items-center">
      <!-- Logo and main navigation links -->
      <div class="flex items-center space-x-4">
        <img src="@/assets/logo.png" alt="Logo" class="h-8 w-auto" />
        <router-link to="/" class="text-white hover:text-gray-300">Strona główna</router-link>
        <template v-if="isAuthenticated">
          <router-link to="/your-account" class="text-white hover:text-gray-300">Twoje konto</router-link>
          <router-link to="/your-sports" class="text-white hover:text-gray-300">Twoje sporty</router-link>
          <router-link to="/dump-account" class="text-white hover:text-gray-300">Debug Account</router-link>
        </template>
      </div>

      <!-- Authentication-related links and user avatar -->
      <div class="flex items-center space-x-4">
        <template v-if="!isAuthenticated">
          <router-link to="/login" class="text-white hover:text-gray-300">Marketplace/Mobile Login</router-link>
        </template>
        <template v-else>
          <button @click="handleLogout" class="text-white hover:text-gray-300">
            Wyloguj
          </button>
          <div
            class="w-8 h-8 rounded-full bg-blue-500 flex items-center justify-center text-white font-bold"
          >
            {{ userInitials }}
          </div>
        </template>
      </div>
    </div>
  </nav>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "NavBar",

  computed: {
    ...mapGetters("user", ["isAuthenticated", "currentUser"]),
    userInitials() {
      const { first_name, last_name } = this.currentUser;
      if (first_name && last_name) {
        return `${first_name[0]}${last_name[0]}`.toUpperCase();
      } else if (first_name) {
        return first_name[0].toUpperCase();
      } else if (last_name) {
        return last_name[0].toUpperCase();
      }
      return "";
    },
  },

  methods: {
    ...mapActions("user", ["logout"]),

    async handleLogout() {
      console.log("handleLogout called");
      await this.logout();
      console.log("Logout completed, redirecting to login page");
      this.$router.push("/login");
    },
  },
};
</script>

<style scoped>
/* Add any component-specific styles here */
</style>
