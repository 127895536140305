<template>
  <div class="container mx-auto px-4 py-8">
    <div v-if="loading" class="text-center">
      <p>Loading venues...</p>
    </div>
    <div v-else-if="error" class="text-center text-red-600">
      <p>{{ error }}</p>
    </div>
    <div v-else class="space-y-8">
      <div
        v-for="venue in venues"
        :key="venue.id"
        class="bg-white shadow-lg rounded-lg overflow-hidden w-full"
      >
        <div class="p-6">
          <div class="flex items-center mb-4">
            <img
              v-if="venue.logo_url"
              :src="venue.logo_url.url"
              :alt="venue.name + ' logo'"
              class="w-16 h-16 object-cover rounded-full mr-4"
            />
            <div>
              <h2 class="text-2xl font-semibold flex items-center">
                <i class="fas fa-map-marker-alt text-red-500 mr-2"></i>
                {{ venue.name }}
              </h2>
              <p class="text-sm text-gray-500">ID: {{ venue.id }}</p>
            </div>
          </div>

          <div class="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
            <div>
              <h3 class="text-lg font-semibold mb-2">Dane kontaktowe</h3>
              <p class="text-gray-600 flex items-center mb-2">
                <i class="fas fa-location-arrow text-blue-500 mr-2"></i>
                {{ venue.address }}
              </p>
              <p class="text-gray-600 flex items-center mb-2">
                <i class="fas fa-phone text-green-500 mr-2"></i>
                {{ formatPhoneNumber(venue.phone) }}
              </p>
              <p class="text-gray-600 flex items-center mb-2">
                <i class="fas fa-envelope text-purple-500 mr-2"></i>
                {{ venue.email }}
              </p>
              <p class="text-gray-600 flex items-center mb-2">
                <i class="fas fa-globe text-indigo-500 mr-2"></i>
                <a
                  :href="venue.website"
                  target="_blank"
                  class="text-blue-500 hover:underline"
                  >{{ venue.website }}</a
                >
              </p>
            </div>
            <div>
              <h3 class="text-lg font-semibold mb-2">Debug</h3>
              <p class="text-gray-700 flex items-center mb-2">
                <i class="fas fa-running text-orange-500 mr-2"></i>
                <span class="font-semibold">Sports:</span>
                {{
                  venue.app_sport_type_id.map((sport) => sport.name).join(", ")
                }}
              </p>
              <p class="text-gray-600 flex items-center mb-2">
                <i class="fas fa-calendar-check text-teal-500 mr-2"></i>
                <span class="font-semibold">Status:</span>
                <span
                  :class="venue.is_active ? 'text-green-500' : 'text-red-500'"
                >
                  {{ venue.is_active ? "Active" : "Inactive" }}
                </span>
              </p>
              <p class="text-gray-600 flex items-center mb-2">
                <i class="fas fa-money-bill-wave text-yellow-500 mr-2"></i>
                <span class="font-semibold">Payments:</span>
                <span
                  :class="
                    venue.payments_overdue ? 'text-red-500' : 'text-green-500'
                  "
                >
                  {{ venue.payments_overdue ? "Overdue" : "Up to date" }}
                </span>
              </p>
              <p class="text-gray-600 flex items-center mb-2">
                <i class="fas fa-clock text-blue-400 mr-2"></i>
                <span class="font-semibold">Created:</span>
                {{ formatDate(venue.created_at) }}
              </p>
            </div>
          </div>

          <div class="mt-4">
            <h3 class="font-semibold mb-2 flex items-center">
              <i class="far fa-clock text-purple-500 mr-2"></i>
              Opening Hours:
            </h3>
            <ul
              class="text-sm grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-2"
            >
              <li
                v-for="(hours, day) in venue.opening_hours"
                :key="day"
                class="flex items-center"
              >
                <i class="fas fa-calendar-day text-gray-400 mr-2"></i>
                <span class="font-medium w-10">{{ day }}:</span>
                <span class="ml-2">{{ formatOpeningHours(hours) }}</span>
              </li>
            </ul>
          </div>

          <div class="mt-4" v-if="venue.geolocation">
            <h3 class="font-semibold mb-2 flex items-center">
              <i class="fas fa-map-marked-alt text-green-500 mr-2"></i>
              Location:
            </h3>
            <div class="h-64 w-full">
              <l-map
                :zoom="13"
                :center="[
                  venue.geolocation.data.lat,
                  venue.geolocation.data.lng,
                ]"
              >
                <l-tile-layer
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                ></l-tile-layer>
                <l-marker
                  :lat-lng="[
                    venue.geolocation.data.lat,
                    venue.geolocation.data.lng,
                  ]"
                >
                  <l-popup>{{ venue.name }}</l-popup>
                </l-marker>
              </l-map>
            </div>
          </div>

          <div class="mt-6">
            <button
              @click="goToReservation(venue.id)"
              class="w-100 bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
            >
              Zarezerwuj
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import { useRouter } from "vue-router";
import venuesApi from "@/api/public";
import { LMap, LTileLayer, LMarker, LPopup } from "vue3-leaflet";
import "leaflet/dist/leaflet.css";

export default {
  name: "Home",
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
  },
  setup() {
    const venues = ref([]);
    const loading = ref(true);
    const error = ref(null);
    const router = useRouter();

    const fetchVenues = async () => {
      try {
        const data = await venuesApi.getVenues();
        venues.value = data;
        loading.value = false;
      } catch (err) {
        console.error(err);
        error.value = "Failed to load venues. Please try again later.";
        loading.value = false;
      }
    };

    const formatOpeningHours = (hours) => {
      if (hours.length === 0) return "Closed";
      const start = formatTime(hours[0]);
      const end = formatTime(hours[1]);
      return `${start} - ${end}`;
    };

    const formatTime = (minutes) => {
      const hours = Math.floor(minutes / 60);
      const mins = minutes % 60;
      return `${hours.toString().padStart(2, "0")}:${mins
        .toString()
        .padStart(2, "0")}`;
    };

    const formatPhoneNumber = (phone) => {
      const phoneStr = phone.toString();
      return phoneStr.replace(/(\d{3})(\d{3})(\d{3})/, "$1 $2 $3");
    };

    const formatDate = (timestamp) => {
      const date = new Date(timestamp);
      return date.toLocaleDateString();
    };

    const goToReservation = (venueId) => {
      router.push({ path: "/reservation", query: { venueId } });
    };

    onMounted(fetchVenues);

    return {
      venues,
      loading,
      error,
      formatOpeningHours,
      formatPhoneNumber,
      formatDate,
      goToReservation,
    };
  },
};
</script>

<style>
@import "leaflet/dist/leaflet.css";
</style>
