<template>
  <div class="p-4">
    <h2 class="text-2xl font-bold mb-6">Twoje konto</h2>
    <form @submit.prevent="handleSubmit">
      <div class="mb-4">
        <label
          class="block text-gray-700 text-sm font-bold mb-2"
          for="first_name"
        >
          Imię:
        </label>
        <input
          id="first_name"
          v-model="first_name"
          type="text"
          required
          class="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
      </div>
      <div class="mb-4">
        <label
          class="block text-gray-700 text-sm font-bold mb-2"
          for="last_name"
        >
          Nazwisko:
        </label>
        <input
          id="last_name"
          v-model="last_name"
          type="text"
          required
          class="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
      </div>
      <div class="mb-4">
        <label class="block text-gray-700 text-sm font-bold mb-2" for="email">
          Email:
        </label>
        <input
          id="email"
          v-model="email"
          type="email"
          required
          class="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
      </div>
      <div class="mb-4">
        <label class="block text-gray-700 text-sm font-bold mb-2">
          Status weryfikacji email:
        </label>
        <span v-if="email_verified" class="text-green-600">Zweryfikowany</span>
        <span v-else class="text-red-600">Niezweryfikowany</span>
      </div>
      <button
        type="submit"
        class="w-full mt-4 bg-blue-500 text-white p-2 rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
      >
        Zapisz zmiany
      </button>
    </form>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";

export default {
  name: "Account",
  data() {
    return {
      first_name: "",
      last_name: "",
      email: "",
      email_verified: false,
    };
  },
  computed: {
    ...mapState("user", ["authToken"]),
    ...mapGetters("user", ["userDetails"]),
  },
  watch: {
    userDetails: {
      immediate: true,
      handler(newUserDetails) {
        console.log("userDetails changed:", newUserDetails);
        this.updateFormData(newUserDetails);
      },
    },
  },
  async created() {
    console.log("Account component created");
    await this.loadUserData();
  },
  methods: {
    ...mapActions("user", ["fetchUserProfile", "updateUserProfile"]),
    updateFormData(userDetails) {
      console.log("Updating form data with userDetails:", userDetails);
      this.first_name = userDetails.first_name;
      this.last_name = userDetails.last_name;
      this.email = userDetails.email;
      this.email_verified = userDetails.email_verified;
      console.log("Form data updated:", { 
        first_name: this.first_name, 
        last_name: this.last_name, 
        email: this.email, 
        email_verified: this.email_verified 
      });
    },
    async loadUserData() {
      console.log("Loading user data, authToken:", this.authToken);
      if (this.authToken) {
        try {
          await this.fetchUserProfile();
          console.log("User profile fetched");
        } catch (error) {
          console.error("Error fetching user profile:", error);
        }
      } else {
        console.log("No authToken, skipping profile fetch");
      }
    },
    async handleSubmit() {
      if (!this.first_name || !this.last_name || !this.email) {
        alert("Wszystkie pola są wymagane. Proszę wypełnić formularz.");
        return;
      }

      try {
        const updatedUserData = {
          first_name: this.first_name,
          last_name: this.last_name,
          email: this.email,
        };
        
        await this.updateUserProfile(updatedUserData);
        console.log("userdata updated:", this.userDetails);
        alert("Dane zostały zaktualizowane pomyślnie.");
      } catch (error) {
        console.error("Error updating user profile:", error);
        alert(
          "Wystąpił błąd podczas aktualizacji danych. Spróbuj ponownie później."
        );
      }
    },
  },
};
</script>
