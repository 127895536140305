<template>
  <div class="p-4">
    <h2 class="text-2xl font-bold mb-6">Sprawdź swoją skrzynkę e-mail</h2>
    <p class="mb-4">
      Na Twój adres e-mail został wysłany magiczny link do logowania. Kliknij w
      link w wiadomości, aby się zalogować.
    </p>
    <p class="mb-4">
      Jeśli nie otrzymałeś wiadomości, sprawdź folder spam lub spróbuj ponownie
      za kilka minut.
    </p>
    <p v-if="error" class="mt-4 text-red-500">{{ error }}</p>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "LoginMagicLink",
  data() {
    return {
      error: "",
      phoneNumber: "",
    };
  },
  created() {
    this.phoneNumber = this.$route.params.phoneNumber;
    if (!this.phoneNumber) {
      console.error("Phone number not provided in route params");
      this.error =
        "Wystąpił błąd. Proszę wrócić do strony logowania i spróbować ponownie.";
    }
  },
  methods: {
    ...mapActions("user", ["initiateOtpLogin"]),
  },
};
</script>
