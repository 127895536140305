<template>
  <div class="p-4">
    <h2 class="text-2xl font-bold mb-6">
      {{ isPhoneStep ? "Podaj numer telefonu" : "Wpisz kod OTP" }}
    </h2>
    <form @submit.prevent="handleSubmit">
      <div v-if="isPhoneStep">
        <label class="block text-gray-700 text-sm font-bold mb-2" for="phone">
          Numer telefonu:
        </label>
        <input
          id="phone"
          v-model="phoneNumber"
          type="tel"
          required
          class="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          placeholder="+48 XXX XXX XXX"
        />
      </div>
      <div v-else>
        <label class="block text-gray-700 text-sm font-bold mb-2" for="otp">
          Kod:
        </label>
        <input
          id="otp"
          v-model="otpCode"
          type="text"
          required
          maxlength="4"
          class="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          placeholder="Wpisz 4-cyfrowy kod"
        />
      </div>
      <button
        type="submit"
        class="w-full mt-4 bg-blue-500 text-white p-2 rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
      >
        {{ isPhoneStep ? "Wyślij kod" : "Weryfikuj" }}
      </button>
    </form>
    <p v-if="error" class="mt-4 text-red-500">{{ error }}</p>
    <button
      v-if="!isPhoneStep"
      @click="resetForm"
      class="mt-4 text-blue-500 hover:underline"
    >
      Wróć do wprowadzania numeru telefonu
    </button>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import apiPublic from "@/api/public";

export default {
  name: "Login",
  data() {
    return {
      isPhoneStep: true,
      phoneNumber: "",
      otpCode: "",
      error: "",
      isLoading: false,
    };
  },
  methods: {
    ...mapActions("user", [
      "initiateOtpLogin",
      "verifyOtpToken",
      "setAuthToken",
      "fetchUserProfile",
    ]),

    async handleSubmit() {
      console.log("handleSubmit called", { isPhoneStep: this.isPhoneStep });
      this.error = "";
      this.isLoading = true;

      try {
        if (this.isPhoneStep) {
          await this.handlePhoneStep();
        } else {
          await this.handleOtpStep();
        }
      } catch (error) {
        console.error("Error in handleSubmit:", error);
        this.error = "Wystąpił nieoczekiwany błąd. Spróbuj ponownie.";
      } finally {
        this.isLoading = false;
      }
    },

    async handlePhoneStep() {
      console.log("Initiating OTP login for", this.phoneNumber);
      try {
        const response = await this.initiateOtpLogin(this.phoneNumber);
        console.log("OTP initiation response:", response);

        if (
          response &&
          response.status === "unsent" &&
          response.email_verified
        ) {
          console.log(
            "Email verified, redirecting to LoginMagicLink and sending email"
          );

          try {
            const magicLinkResponse = await apiPublic.sendEmailMagicLink(
              this.phoneNumber
            );
            console.log("sendEmailMagicLink response", magicLinkResponse);
          } catch (error) {
            console.error("Error sending magic link:", error);
            this.error =
              "Nie udało się wysłać linku magicznego. Spróbuj ponownie.";
            return;
          }

          this.$router.push({
            name: "LoginMagicLink",
            params: { phoneNumber: this.phoneNumber },
          });
        } else if (response && response.status === "success") {
          console.log("OTP initiation successful, moving to OTP input step");
          this.isPhoneStep = false;
        } else {
          console.warn("Unexpected response format:", response);
          this.error =
            "Nie udało się zainicjować procesu logowania. Spróbuj ponownie.";
        }
      } catch (error) {
        console.error("Error during OTP initiation:", error);
        this.error =
          "Wystąpił błąd podczas inicjowania procesu logowania. Spróbuj ponownie.";
      }
    },

    async handleOtpStep() {
      console.log("Verifying OTP token", {
        code: this.otpCode,
        phoneNumber: this.phoneNumber,
      });
      try {
        const response = await this.verifyOtpToken({
          code: this.otpCode,
          phone_number: this.phoneNumber,
        });
        console.log("OTP verification response:", response);

        if (response && response.authToken) {
          console.log(
            "Auth token received, saving to store and fetching user profile"
          );
          await this.setAuthToken(response.authToken);
          await this.fetchUserProfile();
          this.$router.push("/complete-registration");
        } else {
          console.warn("Unexpected response format:", response);
          this.error = "Błędny kod SMS. Spróbuj ponownie.";
        }
      } catch (error) {
        console.error("Error during OTP verification:", error);
        this.error = "Błędny kod SMS. Spróbuj ponownie.";
      }
    },

    resetForm() {
      console.log("Resetting form");
      this.isPhoneStep = true;
      this.phoneNumber = "";
      this.otpCode = "";
      this.error = "";
    },
  },
};
</script>
