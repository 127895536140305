import api from "./index";

export default {
  getProfile(token) {
    console.log("API call: getProfile");
    return api.apiUsers
      .get("/auth/me", { headers: { Authorization: `Bearer ${token}` } })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },
  updateUser(data, authToken) {
    console.log("API call: updateUser", data);
    return api.apiUsers
      .patch("/user", data, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },
  updateUserSportSkill(userId, sportData, authToken) {
    return api.apiUsers
      .post(`/user/sport_skill/${userId}`, sportData, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },
  initiateOtpLogin(data) {
    console.log("API call: initiateOtpLogin", data);
    return api.apiUsers
      .post("/auth/otp_login_signup", data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },

  verifyOtpToken(code, phoneNumber) {
    return api.apiUsers
      .post("/auth/otp_token", { code, phone_number: phoneNumber })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },
};
