import api from "./index";

export default {
  getVenues() {
    console.log("API CALL: venues");
    return api.apiPublic
      .get("/venue")
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },
  getSingleVenue(venueID) {
    console.log("API CALL: getSingleVenue");
    return api.apiPublic
      .get(`/venue/${venueID}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },
  getAppSports() {
    console.log("API CALL: sports");
    return api.apiPublic
      .get("/app/sport_type")
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },
  getUserDataFromMagicToken(magicToken) {
    console.log("API CALL echangeMagicTokenToAuthToken", magicToken);

    return api.apiPublic
      .post(`/user/magic_token`, { magic_token: magicToken })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },
  shortURL(token, userid) {
    console.log("API CALL: shortURL", token, userid);

    return api.apiPublic
      .post("/app/url_shortener", {
        user_id: userid,
        short_url_token: token,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },
  sendEmailMagicLink(phoneNumber) {
    console.log("API: sendEmailMagicLink:", phoneNumber);

    return api.apiPublic
      .post("/app/email_magiclink", { phone_number: phoneNumber })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },
};
