import axios from "axios";

const apiUsersGroup = process.env.VUE_APP_API_GROUP_USERS;
const apiPublicGroup = process.env.VUE_APP_API_GROUP_PUBLIC;
const apiMarketplaceGroup = process.env.VUE_APP_API_GROUP_MARKETPLACE;

const baseUrlUsers = `${process.env.VUE_APP_API_BASE_URL}/${apiUsersGroup}`;
const baseUrlPublic = `${process.env.VUE_APP_API_BASE_URL}/${apiPublicGroup}`;
const baseUrlMarketplace = `${process.env.VUE_APP_API_BASE_URL}/${apiMarketplaceGroup}`;

console.log('API Groups:', { apiUsersGroup, apiPublicGroup, apiMarketplaceGroup });
console.log('Base URLs:', { baseUrlUsers, baseUrlPublic, baseUrlMarketplace });

const apiUsers = axios.create({
  baseURL: baseUrlUsers,
  headers: {
    "Content-Type": "application/json",
  },
});

const apiPublic = axios.create({
  baseURL: baseUrlPublic,
  headers: {
    "Content-Type": "application/json",
  },
});

const apiMarketplace = axios.create({
  baseURL: baseUrlMarketplace,
  headers: {
    "Content-Type": "application/json",
  },
});

export const setAuthToken = (token) => {
  if (token) {
    apiUsers.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  } else {
    delete apiUsers.defaults.headers.common["Authorization"];
  }
};

export const clearAuthToken = () => {
  delete apiUsers.defaults.headers.common["Authorization"];
};

const removeListeners = (obj) => {
  const clean = { ...obj };
  delete clean._listeners;
  return clean;
};

const addLoggingInterceptors = (instance, name) => {
  instance.interceptors.response.use(
    (response) => {
      console.debug(`API ${name} Response:`, removeListeners(response.data));
      return response;
    },
    (error) => {
      console.error(
        `API ${name} Error:`,
        error.response ? removeListeners(error.response.data) : error.message
      );
      return Promise.reject(error);
    }
  );
};

addLoggingInterceptors(apiUsers, "Users");
addLoggingInterceptors(apiPublic, "Public");
addLoggingInterceptors(apiMarketplace, "Marketplace");

export default {
  apiUsers,
  apiPublic,
  apiMarketplace,
};
