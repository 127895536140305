<template>
  <div class="p-4">
    <h2 class="text-2xl font-bold mb-6">Potwierdź adres email</h2>
    <div v-if="loading" class="mb-4">
      <p class="text-gray-700">Trwa potwierdzanie Twojego adresu email...</p>
    </div>
    <div v-else-if="error" class="mb-4">
      <p class="text-sm text-red-500">{{ error }}</p>
    </div>
    <div v-else class="mb-4">
      <p class="text-sm text-green-600">
        Adres email został pomyślnie potwierdzony. Przekierowujemy Cię na stronę
        główną...
      </p>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import apiPublic from "@/api/public";
import apiUsers from "@/api/users";

export default {
  name: "ConfirmEmail",
  data() {
    return {
      loading: true,
      error: null,
    };
  },
  computed: {
    ...mapState("user", ["authToken"]),
  },
  methods: {
    ...mapActions("user", ["setCurrentUser"]),
    async confirmEmail() {
      const urlParams = new URLSearchParams(window.location.search);
      const fullToken = urlParams.get("token");

      if (!fullToken || fullToken.length <= 12) {
        this.error = "Nieprawidłowy token w adresie URL.";
        this.loading = false;
        return;
      }

      const token = fullToken.slice(0, 12);
      const userIdHex = fullToken.slice(12);
      const userId = parseInt(userIdHex, 16).toString();

      console.log("token:", token);
      console.log("userID", userId);

      try {
        // Call shortURL to get the magicToken
        const magicToken = await apiPublic.shortURL(fullToken, userId);
        console.log("magicToken:", magicToken);

        if (
          !magicToken ||
          typeof magicToken !== "string" ||
          magicToken.trim() === ""
        ) {
          throw new Error("Nie udało się uzyskać poprawnego magicToken");
        }

        // Now use the magicToken to get authToken
        const authToken = await apiPublic.getUserDataFromMagicToken(magicToken);
        console.log("authToken:", authToken);

        if (
          !authToken ||
          typeof authToken !== "string" ||
          authToken.trim() === ""
        ) {
          throw new Error("Nie udało się uzyskać poprawnego authToken");
        } else {
          // Store the authToken in the Vuex store
          await this.setCurrentUser({ authToken });
        }

        // Use the authToken from the Vuex store to get user profile
        const userProfile = await apiUsers.getProfile(this.authToken);
        console.log("userProfile:", userProfile);
        await this.setCurrentUser(userProfile);
        this.$router.push("/");
      } catch (error) {
        console.error("Error during email confirmation:", error);
        this.error =
          error.message ||
          "Wystąpił błąd podczas potwierdzania adresu email. Spróbuj ponownie później.";
      } finally {
        this.loading = false;
      }
    },
  },
  mounted() {
    this.confirmEmail();
  },
};
</script>
