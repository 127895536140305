import userApi from "@/api/users";

export default {
  namespaced: true,
  state: {
    user: JSON.parse(localStorage.getItem("user")) || {},
    authToken: localStorage.getItem("authToken") || null,
  },
  mutations: {
    setUser(state, user) {
      console.log("Mutation: setUser", user);
      state.user = user; // Replace the entire user object
      localStorage.setItem("user", JSON.stringify(state.user));
    },
    setAuthToken(state, token) {
      console.log("Mutation: setAuthToken", token);
      state.authToken = token;
      localStorage.setItem("authToken", token);
    },
    clearAuth(state) {
      console.log("Mutation: clearAuth");
      state.user = {};
      state.authToken = null;
      localStorage.removeItem("user");
      localStorage.removeItem("authToken");
    },
  },
  actions: {
    async initiateOtpLogin(_, phoneNumber) {
      console.log("Action: initiateOtpLogin", phoneNumber);
      try {
        const response = await userApi.initiateOtpLogin({
          source: "web",
          phone_number: phoneNumber,
        });
        console.log("initiateOtpLogin response:", response);
        return response;
      } catch (error) {
        console.error("Error in initiateOtpLogin:", error);
        throw error;
      }
    },
    async verifyOtpToken(_, { code, phone_number }) {
      console.log("Action: verifyOtpToken", { code, phone_number });
      try {
        const response = await userApi.verifyOtpToken(code, phone_number);
        console.log("verifyOtpToken response:", response);
        return response;
      } catch (error) {
        console.error("Error in verifyOtpToken:", error);
        throw error;
      }
    },
    setAuthToken({ commit }, token) {
      console.log("Action: setAuthToken", token);
      commit("setAuthToken", token);
    },
    setCurrentUser({ commit }, user) {
      console.log("Action: setCurrentUser", user);
      commit("setUser", user);
      if (user.authToken) {
        commit("setAuthToken", user.authToken);
      }
    },
    async fetchUserProfile({ commit, state }) {
      console.log(
        "Action: fetchUserProfile, current authToken:",
        state.authToken
      );
      try {
        console.log("Calling userApi.getProfile");
        const response = await userApi.getProfile(state.authToken);
        console.log("fetchUserProfile raw response:", response);
        if (!response || !response.id) {
          console.error("Error: User profile response is missing ID", response);
          throw new Error("User profile response is missing ID");
        }
        console.log("Committing user data to store:", response);
        commit("setUser", response);
        return response;
      } catch (error) {
        console.error("Error in fetchUserProfile:", error);
        throw error;
      }
    },
    async updateUserProfile({ commit, state }, userData) {
      console.log("Action: updateUserProfile", userData);
      try {
        const response = await userApi.updateUser(userData, state.authToken);
        console.log("updateUserProfile response:", response);
        commit("setUser", response);
        return response;
      } catch (error) {
        console.error("Error in updateUserProfile:", error);
        throw error;
      }
    },
    logout({ commit }) {
      console.log("Action: logout");
      commit("clearAuth");
    },
  },
  getters: {
    isAuthenticated: (state) => !!state.authToken,
    currentUser: (state) => state.user,
    userDetails: (state) => ({
      first_name: state.user.first_name || "",
      last_name: state.user.last_name || "",
      email: state.user.email || "",
    }),
  },
};
