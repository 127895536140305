import { createRouter, createWebHistory } from "vue-router";
import Home from "@/views/Home.vue";
import Login from "@/views/users/Login.vue";
import LoginMagicLink from "@/views/users/LoginMagicLink.vue";
import CompleteRegistration from "@/views/users/CompleteRegistration.vue";
import Account from "@/views/users/Account.vue";
import YourSports from "@/views/users/YourSports.vue";
import DumpAccount from "@/views/users/DumpAccount.vue";
import ConfirmEmail from "@/views/users/ConfirmEmail.vue";
import Reservation from "@/views/venues/reservation.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: { requiresAuth: false },
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/login-magic-link",
    name: "LoginMagicLink",
    component: LoginMagicLink,
  },
  {
    path: "/complete-registration",
    name: "CompleteRegistration",
    component: CompleteRegistration,
    meta: { requiresAuth: true },
  },
  {
    path: "/your-account",
    name: "YourAccount",
    component: Account,
    meta: { requiresAuth: true },
  },
  {
    path: "/your-sports",
    name: "YourSports",
    component: YourSports,
    meta: { requiresAuth: true },
  },
  {
    path: "/dump-account",
    name: "DumpAccount",
    component: DumpAccount,
    meta: { requiresAuth: true },
  },
  {
    path: "/confirm-email",
    name: "ConfirmEmail",
    component: ConfirmEmail,
    meta: { requiresAuth: false },
  },
  {
    path: "/reservation",
    name: "reservation",
    component: Reservation,
    meta: { requiresAuth: false },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
