<template>
  <div class="flex flex-col">
    <div class="container mx-auto px-4 py-8 flex-grow">
      <h1 class="text-4xl font-bold mb-8 text-center text-blue-600">
        Account Debug Information
      </h1>
      <div
        v-if="userData"
        class="bg-white shadow-lg rounded-lg overflow-hidden"
      >
        <div class="bg-blue-500 text-white py-4 px-6">
          <h2 class="text-2xl font-semibold">User Profile</h2>
        </div>
        <div class="p-6">
          <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div class="bg-gray-50 p-4 rounded-lg">
              <p class="mb-2">
                <span class="font-bold text-blue-600">ID:</span>
                {{ userData.id }}
              </p>
              <p class="mb-2">
                <span class="font-bold text-blue-600">Created At:</span>
                {{ formatDate(userData.created_at) }}
              </p>
              <p class="mb-2">
                <span class="font-bold text-blue-600">First Name:</span>
                {{ userData.first_name }}
              </p>
              <p class="mb-2">
                <span class="font-bold text-blue-600">Last Name:</span>
                {{ userData.last_name }}
              </p>
            </div>
            <div class="bg-gray-50 p-4 rounded-lg">
              <p class="mb-2">
                <span class="font-bold text-blue-600">Email:</span>
                {{ userData.email }}
              </p>
              <p class="mb-2">
                <span class="font-bold text-blue-600">Email Verified:</span>
                <span
                  :class="
                    userData.email_verified ? 'text-green-600' : 'text-red-600'
                  "
                >
                  {{ userData.email_verified ? "Yes" : "No" }}
                </span>
              </p>
              <p class="mb-2">
                <span class="font-bold text-blue-600">Marketing Consent:</span>
                <span
                  :class="
                    userData.marketing_consent
                      ? 'text-green-600'
                      : 'text-red-600'
                  "
                >
                  {{ userData.marketing_consent ? "Yes" : "No" }}
                </span>
              </p>
            </div>
          </div>
        </div>

        <div class="bg-blue-500 text-white py-4 px-6">
          <h3 class="text-xl font-semibold">Venue Profiles</h3>
        </div>
        <div class="p-6">
          <div
            v-if="userData._user_venue_profiles.length > 0"
            class="overflow-x-auto"
          >
            <table class="w-full table-auto border-collapse">
              <thead>
                <tr class="bg-gray-200">
                  <th class="px-4 py-2 text-left">Venue ID</th>
                  <th class="px-4 py-2 text-left">First Name</th>
                  <th class="px-4 py-2 text-left">Last Name</th>
                  <th class="px-4 py-2 text-left">Active</th>
                  <th class="px-4 py-2 text-left">Marketing Consent</th>
                  <th class="px-4 py-2 text-left">Annotation</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="profile in userData._user_venue_profiles"
                  :key="profile.venue_id"
                  class="border-b hover:bg-gray-100"
                >
                  <td class="px-4 py-2">{{ profile.venue_id }}</td>
                  <td class="px-4 py-2">{{ profile.first_name }}</td>
                  <td class="px-4 py-2">{{ profile.last_name }}</td>
                  <td class="px-4 py-2">
                    <span
                      :class="
                        profile.is_active ? 'text-green-600' : 'text-red-600'
                      "
                    >
                      {{ profile.is_active ? "Yes" : "No" }}
                    </span>
                  </td>
                  <td class="px-4 py-2">
                    <span
                      :class="
                        profile.marketing_consent
                          ? 'text-green-600'
                          : 'text-red-600'
                      "
                    >
                      {{ profile.marketing_consent ? "Yes" : "No" }}
                    </span>
                  </td>
                  <td class="px-4 py-2">{{ profile.annotation }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <p v-else class="mt-2 text-gray-600">No venue profiles available.</p>
        </div>

        <div class="bg-blue-500 text-white py-4 px-6">
          <h3 class="text-xl font-semibold">Sport Skills</h3>
        </div>
        <div class="p-6">
          <div
            v-if="userData._user_sport_skill.length > 0"
            class="overflow-x-auto"
          >
            <table class="w-full table-auto border-collapse">
              <thead>
                <tr class="bg-gray-200">
                  <th class="px-4 py-2 text-left">ID</th>
                  <th class="px-4 py-2 text-left">Sport Type ID</th>
                  <th class="px-4 py-2 text-left">Level</th>
                  <th class="px-4 py-2 text-left">Updated At</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="skill in userData._user_sport_skill"
                  :key="skill.id"
                  class="border-b hover:bg-gray-100"
                >
                  <td class="px-4 py-2">{{ skill.id }}</td>
                  <td class="px-4 py-2">{{ skill.app_sport_type_id }}</td>
                  <td class="px-4 py-2">{{ skill.level }}</td>
                  <td class="px-4 py-2">{{ formatDate(skill.updated_at) }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <p v-else class="mt-2 text-gray-600">No sport skills available.</p>
        </div>
      </div>
      <div v-else class="text-center bg-white shadow-lg rounded-lg p-8">
        <p class="text-xl text-gray-600">Loading user data...</p>
      </div>
    </div>
  </div>
</template>

<script>
import usersApi from "@/api/users";

export default {
  name: "DumpAccount",
  data() {
    return {
      userData: null,
    };
  },
  created() {
    this.fetchUserProfile();
  },
  methods: {
    async fetchUserProfile() {
      try {
        const token = localStorage.getItem("authToken");
        if (!token) {
          console.error("No auth token found");
          return;
        }
        this.userData = await usersApi.getProfile(token);
      } catch (error) {
        console.error("Error fetching user profile:", error);
      }
    },
    formatDate(timestamp) {
      return new Date(timestamp).toLocaleString();
    },
  },
};
</script>

<style scoped>
.container {
  max-width: 1200px;
}
</style>
