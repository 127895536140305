<template>
  <div class="p-4">
    <h2 class="text-2xl font-bold mb-6">Dokończ rejestrację</h2>
    <form @submit.prevent="handleSubmit">
      <div class="mb-4">
        <label
          class="block text-gray-700 text-sm font-bold mb-2"
          for="first_name"
        >
          Imię:
        </label>
        <input
          id="first_name"
          v-model="first_name"
          type="text"
          required
          class="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
      </div>
      <div class="mb-4">
        <label
          class="block text-gray-700 text-sm font-bold mb-2"
          for="last_name"
        >
          Nazwisko:
        </label>
        <input
          id="last_name"
          v-model="last_name"
          type="text"
          required
          class="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
      </div>
      <div class="mb-4">
        <label class="block text-gray-700 text-sm font-bold mb-2" for="email">
          Email:
        </label>
        <input
          id="email"
          v-model="email"
          type="email"
          required
          class="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
      </div>
      <div class="mb-4">
        <label class="flex items-center">
          <input
            type="checkbox"
            v-model="acceptTerms"
            required
            class="form-checkbox h-5 w-5 text-blue-600"
          />
          <span class="ml-2 text-gray-700"
            >Akceptuję warunki regulaminu Playmore</span
          >
        </label>
      </div>
      <button
        type="submit"
        class="w-full mt-4 bg-blue-500 text-white p-2 rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
      >
        Zapisz
      </button>
    </form>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import apiUsers from "@/api/users";

export default {
  name: "CompleteRegistration",
  data() {
    return {
      first_name: "",
      last_name: "",
      email: "",
      acceptTerms: false,
    };
  },
  computed: {
    ...mapState("user", ["currentUser", "authToken"]),
  },
  mounted() {
    console.log("CompleteRegistration mounted, currentUser:", this.currentUser);
    if (this.currentUser) {
      this.first_name = this.currentUser.first_name || "";
      this.last_name = this.currentUser.last_name || "";
      this.email = this.currentUser.email || "";
    }
  },
  methods: {
    ...mapActions("user", ["setCurrentUser"]),
    async handleSubmit() {
      console.log("handleSubmit called, currentUser:", this.currentUser);
      if (
        !this.first_name ||
        !this.last_name ||
        !this.email ||
        !this.acceptTerms
      ) {
        alert(
          "Wszystkie pola są wymagane. Proszę wypełnić formularz i zaakceptować warunki regulaminu."
        );
        return;
      }

      try {
        const updatedUser = await apiUsers.updateUser(
          {
            first_name: this.first_name,
            last_name: this.last_name,
            email: this.email,
          },
          this.authToken
        );

        // Log in the user after successful update
        this.setCurrentUser({
          first_name: updatedUser.first_name,
          last_name: updatedUser.last_name,
          email: updatedUser.email,
          authToken: this.authToken,
        });

        this.$router.push("/");
      } catch (error) {
        console.error("Error updating user profile:", error);
        alert(
          "Wystąpił błąd podczas aktualizacji profilu. Spróbuj ponownie później."
        );
      }
    },
  },
};
</script>
