<template>
  <div class="flex flex-col">
    <div class="flex">
      <div class="bg-white p-6 rounded-lg shadow-md w-1/2 mr-4">
        <h2 class="text-2xl font-bold mb-4">Venue Availability Check</h2>
        <form @submit.prevent="checkAvailability" class="space-y-4">
          <div>
            <label for="venue_id" class="block mb-1">Venue ID:</label>
            <input
              id="venue_id"
              v-model="form.venue_id"
              type="text"
              required
              class="w-full p-2 border rounded"
            />
          </div>
          <div>
            <label for="venue_sport_object_id" class="block mb-1"
              >Sport Object ID:</label
            >
            <input
              id="venue_sport_object_id"
              v-model="form.venue_sport_object_id"
              type="text"
              required
              class="w-full p-2 border rounded"
            />
          </div>
          <div>
            <label for="start_time" class="block mb-1">Start Time:</label>
            <input
              id="start_time"
              v-model="form.start_time"
              type="datetime-local"
              required
              class="w-full p-2 border rounded"
              :step="1800"
            />
          </div>
          <div>
            <label for="end_time" class="block mb-1">End Time:</label>
            <input
              id="end_time"
              v-model="form.end_time"
              type="datetime-local"
              required
              class="w-full p-2 border rounded"
              :step="1800"
            />
          </div>
          <button
            type="submit"
            :disabled="isLoading"
            class="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 disabled:bg-blue-300"
          >
            {{ isLoading ? "Checking..." : "Check Availability" }}
          </button>
        </form>
      </div>

      <div class="bg-white p-6 rounded-lg shadow-md w-1/2">
        <h2 class="text-2xl font-bold mb-4">Object Availabilities</h2>
        <form @submit.prevent="getObjectAvailabilities" class="space-y-4">
          <div>
            <label for="obj_venue_id" class="block mb-1">Venue ID:</label>
            <input
              id="obj_venue_id"
              v-model="objectForm.venue_id"
              type="text"
              required
              class="w-full p-2 border rounded"
            />
          </div>
          <div>
            <label for="tag_id" class="block mb-1">Tag ID:</label>
            <input
              id="tag_id"
              v-model="objectForm.tag_id"
              type="text"
              required
              class="w-full p-2 border rounded"
            />
          </div>
          <div>
            <label for="date" class="block mb-1">Date:</label>
            <input
              id="date"
              v-model="objectForm.date"
              type="date"
              required
              class="w-full p-2 border rounded"
            />
          </div>
          <button
            type="submit"
            :disabled="isObjectLoading"
            class="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 disabled:bg-green-300"
          >
            {{ isObjectLoading ? "Fetching..." : "Get Availabilities" }}
          </button>
        </form>
      </div>
    </div>

    <div
      v-if="availabilityResponse"
      class="mt-8 bg-white p-6 rounded-lg shadow-md"
    >
      <h3 class="text-xl font-semibold mb-4">Availability Visualization</h3>
      <div
        v-for="(priceTable, index) in availabilityResponse.price_tables"
        :key="index"
        class="mb-6"
      >
        <h4 class="text-lg font-semibold mb-2">
          {{ priceTable.price_list_name }}
        </h4>
        <div class="flex flex-wrap">
          <div
            v-for="(price, time) in priceTable.price_list_hours"
            :key="time"
            class="w-24 h-24 border border-gray-300 m-1 p-2 text-center flex flex-col justify-center"
          >
            <div>{{ formatTime(parseInt(time)) }}</div>
            <div class="font-bold">{{ price }} PLN</div>
          </div>
        </div>
        <div class="mt-2">
          <strong>Calculated Price:</strong>
          {{ priceTable.reservation_calculated_price }} PLN
        </div>
        <div>
          <strong>Payment Methods:</strong>
          {{ priceTable.price_list_payment_methods.join(", ") }}
        </div>
      </div>
      <div class="mt-4">
        <strong>Time to Free Cancellation:</strong>
        {{ availabilityResponse.time_to_free_cancellation }} hours
      </div>
    </div>

    <div
      v-if="objectAvailabilitiesResponse"
      class="mt-8 bg-white p-6 rounded-lg shadow-md"
    >
      <h3 class="text-xl font-semibold mb-4">
        Object Availabilities Visualization
      </h3>
      <div
        v-for="object in objectAvailabilitiesResponse"
        :key="object.venue_sport_object_id"
        class="mb-8"
      >
        <h4 class="text-lg font-semibold mb-2">
          {{ object.venue_sport_object_name }} (ID:
          {{ object.venue_sport_object_id }})
        </h4>
        <div
          v-for="(
            availabilities, duration
          ) in object.venue_sport_object_availability"
          :key="duration"
          class="mb-4"
        >
          <h5 class="font-semibold mb-2">Duration: {{ duration }} minutes</h5>
          <div class="flex flex-wrap">
            <div
              v-for="time in availabilities"
              :key="time"
              class="w-24 h-12 border border-green-300 bg-green-100 m-1 p-2 text-center flex items-center justify-center"
            >
              {{ formatTime(time) }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="availabilityResponse"
      class="mt-8 bg-white p-6 rounded-lg shadow-md"
    >
      <h3 class="text-xl font-semibold mb-2">Availability Response (JSON):</h3>
      <pre class="bg-gray-100 p-4 rounded overflow-x-auto mt-4">{{
        JSON.stringify(availabilityResponse, null, 2)
      }}</pre>
    </div>

    <div
      v-if="errorMessage"
      class="mt-8 bg-white p-6 rounded-lg shadow-md text-red-600"
    >
      <h3 class="text-xl font-semibold mb-2">Error:</h3>
      <pre class="bg-red-100 p-4 rounded overflow-x-auto">{{
        errorMessage
      }}</pre>
    </div>

    <div
      v-if="objectAvailabilitiesResponse"
      class="mt-8 bg-white p-6 rounded-lg shadow-md"
    >
      <h3 class="text-xl font-semibold mb-2">
        Object Availabilities Response (JSON):
      </h3>
      <pre class="bg-gray-100 p-4 rounded overflow-x-auto mt-4">{{
        JSON.stringify(objectAvailabilitiesResponse, null, 2)
      }}</pre>
    </div>

    <div
      v-if="objectErrorMessage"
      class="mt-8 bg-white p-6 rounded-lg shadow-md text-red-600"
    >
      <h3 class="text-xl font-semibold mb-2">Error:</h3>
      <pre class="bg-red-100 p-4 rounded overflow-x-auto">{{
        objectErrorMessage
      }}</pre>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { useRoute } from "vue-router";
import {
  tmp_check_availability,
  tmp_get_object_availabilities,
} from "@/api/marketplace";

const route = useRoute();
const form = ref({
  venue_id: "",
  venue_sport_object_id: "44",
  start_time: "",
  end_time: "",
});

const objectForm = ref({
  venue_id: "",
  tag_id: "8",
  date: "",
});

const availabilityResponse = ref(null);
const errorMessage = ref(null);
const isLoading = ref(false);

const objectAvailabilitiesResponse = ref(null);
const objectErrorMessage = ref(null);
const isObjectLoading = ref(false);

const roundToHalfHour = (date) => {
  const minutes = date.getMinutes();
  const roundedMinutes = minutes >= 30 ? 30 : 0;
  date.setMinutes(roundedMinutes, 0, 0);
  return date;
};

const setDefaultTimes = () => {
  const now = new Date();
  const startTime = roundToHalfHour(now);
  const endTime = new Date(startTime.getTime() + 60 * 60 * 1000);

  form.value.start_time = startTime.toISOString().slice(0, 16);
  form.value.end_time = endTime.toISOString().slice(0, 16);
};

const setDefaultDate = () => {
  const now = new Date();
  objectForm.value.date = now.toISOString().split("T")[0];
};

onMounted(() => {
  const venueId = route.query.venueId || "";
  form.value.venue_id = venueId;
  objectForm.value.venue_id = venueId;
  setDefaultTimes();
  setDefaultDate();
});

const validateForm = () => {
  if (!form.value.venue_id || !form.value.venue_sport_object_id) {
    throw new Error("Venue ID and Sport Object ID are required");
  }

  const startTime = new Date(form.value.start_time);
  const endTime = new Date(form.value.end_time);

  if (isNaN(startTime.getTime()) || isNaN(endTime.getTime())) {
    throw new Error("Invalid date/time format");
  }

  if (startTime >= endTime) {
    throw new Error("End time must be after start time");
  }
};

const checkAvailability = async () => {
  try {
    validateForm();
    isLoading.value = true;

    const startTimestamp = new Date(form.value.start_time).getTime();
    const endTimestamp = new Date(form.value.end_time).getTime();

    const response = await tmp_check_availability({
      venue_id: form.value.venue_id,
      venue_sport_object_id: form.value.venue_sport_object_id,
      start_time: startTimestamp,
      end_time: endTimestamp,
    });

    availabilityResponse.value = response;
    errorMessage.value = null;
    console.log("Availability response:", response);
  } catch (err) {
    console.error("Failed to check availability:", err);
    availabilityResponse.value = null;
    if (err.response && err.response.data) {
      const { traceId, code, message, payload } = err.response.data;
      const formattedError = {
        API_Marketplace_Error: {
          traceId,
          code,
          message,
          payload,
        },
      };
      errorMessage.value = JSON.stringify(formattedError, null, 2);
    } else {
      errorMessage.value = JSON.stringify({ Error: err.message }, null, 2);
    }
  } finally {
    isLoading.value = false;
  }
};

const validateObjectForm = () => {
  if (
    !objectForm.value.venue_id ||
    !objectForm.value.tag_id ||
    !objectForm.value.date
  ) {
    throw new Error("Venue ID, Tag ID, and Date are required");
  }

  const selectedDate = new Date(objectForm.value.date);
  if (isNaN(selectedDate.getTime())) {
    throw new Error("Invalid date format");
  }
};

const getObjectAvailabilities = async () => {
  try {
    validateObjectForm();
    isObjectLoading.value = true;

    const response = await tmp_get_object_availabilities({
      venue_id: objectForm.value.venue_id,
      tag_id: objectForm.value.tag_id,
      date: objectForm.value.date,
    });

    objectAvailabilitiesResponse.value = response;
    objectErrorMessage.value = null;
    console.log("Object availabilities response:", response);
  } catch (err) {
    console.error("Failed to get object availabilities:", err);
    objectAvailabilitiesResponse.value = null;
    if (err.response && err.response.data) {
      const { traceId, code, message, payload } = err.response.data;
      const formattedError = {
        API_Marketplace_Error: {
          traceId,
          code,
          message,
          payload,
        },
      };
      objectErrorMessage.value = JSON.stringify(formattedError, null, 2);
    } else {
      objectErrorMessage.value = JSON.stringify(
        { Error: err.message },
        null,
        2
      );
    }
  } finally {
    isObjectLoading.value = false;
  }
};

const formatTime = (minutes) => {
  const hours = Math.floor(minutes / 60);
  const mins = minutes % 60;
  return `${hours.toString().padStart(2, "0")}:${mins
    .toString()
    .padStart(2, "0")}`;
};
</script>

<style scoped>
/* Add any component-specific styles here */
</style>
