<template>
  <div class="p-4">
    <h2 class="text-2xl font-bold mb-6">Twoje sporty</h2>
    <table class="w-full border-collapse border border-gray-300">
      <thead>
        <tr class="bg-gray-100">
          <th class="border border-gray-300 px-4 py-2">ID</th>
          <th class="border border-gray-300 px-4 py-2">Nazwa</th>
          <th class="border border-gray-300 px-4 py-2">
            Ostatnia aktualizacja
          </th>
          <th class="border border-gray-300 px-4 py-2">Zmień poziom</th>
          <th class="border border-gray-300 px-4 py-2">Wybrany poziom</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="sport in sportsWithLevels" :key="sport.id">
          <td class="border border-gray-300 px-4 py-2">{{ sport.id }}</td>
          <td class="border border-gray-300 px-4 py-2">{{ sport.name }}</td>
          <td class="border border-gray-300 px-4 py-2">
            {{ formatDate(sport.updated_at) }}
          </td>
          <td class="border border-gray-300 px-4 py-2">
            <select
              v-model="sport.newLevel"
              class="border border-gray-300 rounded px-2 py-1"
              @change="updateSportSkill(sport)"
            >
              <option v-for="level in 7" :key="level - 1" :value="level - 1">
                {{ level - 1 }}
              </option>
            </select>
          </td>
          <td class="border border-gray-300 px-4 py-2">{{ sport.level }}</td>
        </tr>
      </tbody>
    </table>
    <p v-if="sportsWithLevels.length === 0" class="mt-4">
      Brak dostępnych sportów.
    </p>
  </div>
</template>

<script>
import apiPublic from "@/api/public";
import apiUsers from "@/api/users";

export default {
  name: "YourSports",
  data() {
    return {
      sports: [],
      userSportSkills: [],
      currentUserId: null,
    };
  },
  computed: {
    sportsWithLevels() {
      return this.sports.map((sport) => {
        const userSkill = this.userSportSkills.find(
          (skill) => skill.app_sport_type_id === sport.id
        );
        return {
          ...sport,
          level: userSkill ? userSkill.level : 0,
          updated_at: userSkill ? userSkill.updated_at : null,
          newLevel: userSkill ? userSkill.level : 0,
        };
      });
    },
  },
  created() {
    this.fetchSports();
    this.fetchUserProfile();
  },
  methods: {
    async fetchSports() {
      try {
        this.sports = await apiPublic.getAppSports();
      } catch (error) {
        console.error("Error fetching sports:", error);
      }
    },
    async fetchUserProfile() {
      try {
        const token = localStorage.getItem("authToken");
        if (!token) {
          console.error("No auth token found");
          return;
        }
        const profile = await apiUsers.getProfile(token);
        this.userSportSkills = profile._user_sport_skill || [];
        this.currentUserId = profile.id;
      } catch (error) {
        console.error("Error fetching user profile:", error);
      }
    },
    formatDate(timestamp) {
      return timestamp ? new Date(timestamp).toLocaleString() : "N/A";
    },
    async updateSportSkill(sport) {
      try {
        const token = localStorage.getItem("authToken");
        if (!token || !this.currentUserId) {
          console.error("No auth token or user ID found");
          return;
        }
        await apiUsers.updateUserSportSkill(
          this.currentUserId,
          {
            app_sport_type_id: sport.id,
            level: sport.newLevel,
          },
          token
        );
        // Update local data
        const updatedSkill = this.userSportSkills.find(
          (skill) => skill.app_sport_type_id === sport.id
        );
        if (updatedSkill) {
          updatedSkill.level = sport.newLevel;
          updatedSkill.updated_at = Date.now();
        } else {
          this.userSportSkills.push({
            app_sport_type_id: sport.id,
            level: sport.newLevel,
            updated_at: Date.now(),
          });
        }
        // Force re-render
        this.$forceUpdate();
      } catch (error) {
        console.error("Error updating sport skill:", error);
      }
    },
  },
};
</script>
