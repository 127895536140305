import api from "./index";

const tmp_check_availability = (data) => {
  return api.apiMarketplace
    .get(`/tmp_check_availability`, {
      params: {
        venue_id: data.venue_id,
        venue_sport_object_id: data.venue_sport_object_id,
        start_time: data.start_time,
        end_time: data.end_time,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

const tmp_get_object_availabilities = (data) => {
  return api.apiMarketplace
    .get(`/tmp_get_object_availabilities`, {
      params: {
        venue_id: data.venue_id,
        tag_id: data.tag_id,
        date: data.date,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export { tmp_check_availability, tmp_get_object_availabilities };
